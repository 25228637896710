<template>
  <div class="matched-list">
    <div class="matched-list-title">
      <span class="fw-bold">
        {{
          $t('creditMatchModal.matchList.total', { matched: formatMoney(total - balance), total: formatMoney(total) })
        }}
      </span>
      <span>{{ $t('creditMatchModal.matchList.balance', { balance: formatMoney(balance) }) }}</span>
    </div>
    <div class="list-wrapper">
      <div v-for="(link, index) in matchedLinks" :key="index" class="matched-item">
        <div>
          <span> {{ formatMoney(link.amount) }}</span>
          <span>
            {{ $t(`document.exports.schema.type.shortName.${link.document.type}`) }} {{ link.document.documentNumber }}
          </span>
        </div>
        <div class="not-hovered-icon">
          <LinkIcon />
        </div>
        <div class="hovered-icon">
          <Button class="icon-btn" @click="$emit('unlink-matched', link)">
            <UnlinkIcon />
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LinkIcon, UnlinkIcon } from '@/assets/icons';
import { Button } from '@/modules/core';
import { useCurrency } from '@/modules/core/compositions/money-currency';

export default {
  components: { LinkIcon, UnlinkIcon, Button },
  props: {
    total: { type: Number, required: true },
    balance: { type: Number, required: true },
    matchedLinks: { type: Array, default: () => [] },
  },
  setup() {
    const { formatCentsToCurrency } = useCurrency();
    return { formatCentsToCurrency };
  },
  methods: {
    formatMoney(value) {
      return this.formatCentsToCurrency(Math.abs(Number(value)));
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
.matched-list {
  color: $typography-primary;
  padding: 5px;

  .matched-list-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 0.84em;
    font-weight: 400;
  }
  .list-wrapper {
    overflow: scroll;
    max-height: 30vh;

    .matched-item {
      height: 52px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;

      .hovered-icon {
        display: none;

        .icon-btn {
          padding: 2px;
          border: 1px solid rgba(0, 0, 0, 0.15);
        }
      }

      .not-hovered-icon {
        padding: 2px;
      }

      &:hover {
        background: $secondary;
        border-radius: 6px;

        .not-hovered-icon {
          display: none;
        }
        .hovered-icon {
          display: inline;
        }
      }
    }
  }
}
</style>
