import { gql } from '@apollo/client/core';

export const ORDER_DIFFERENCES_QUERY = gql`
  query getDifferences($businessId: ID!, $supplierId: ID, $from: ISODate, $to: ISODate) {
    orders(businessId: $businessId, supplierId: $supplierId, fromDate: $from, toDate: $to) {
      nodes {
        id
        date
        amount
        source {
          document: documentNew2 {
            id
            documentNumber
            type
          }
        }
        products {
          id
          product {
            id
            sku
            name
          }
          quantity
          price
          discount
        }
        diffs {
          fieldPath
          customerValue
          supplierValue
        }
        differences {
          customerItemId
          product {
            id
            name
            sku
          }
          quantity {
            customerValue
            supplierValue
            solved
          }
          netPrice {
            customerValue
            supplierValue
            solved
          }
          price {
            customerValue
            supplierValue
            solved
          }
          discount {
            customerValue
            supplierValue
            solved
          }
          amount
          pricingAmount
          quantityAmount
        }
        billings {
          id
          netAmount
          orderLinks {
            externalRef
            order {
              id
            }
            amount
          }
          imbalances {
            billedAmounts {
              id
              amount
            }
            orderedAmounts {
              id
              amount
            }
          }
          source {
            document: documentNew2 {
              id
              documentNumber
              type
            }
          }
        }
      }
    }
  }
`;
