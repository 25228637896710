import { isNil, uniq, pick, prop, map, pipe, reject, sum, flatten } from 'ramda';
import i18n from '@/imports/startup/client/i18n';

export const getDiffsTypes = (order) => {
  const types = [];
  order.diffs.forEach((diff) => {
    let type = diff.fieldPath.split('.')[2];
    if (type === 'discount') {
      type = 'price';
    }
    types.push(type);
  });
  return uniq(types);
};

export const createDifferenceRow = (order) => {
  const orderRelatedBalance = order.billings
    .filter((billing) => billing.netAmount > 0)
    .reduce((total, billing) => {
      const amount = billing.imbalances
        .filter((imbalance) => imbalance.orderedAmounts.find(({ id }) => id === order.id))
        .reduce(
          (total, imbalance) => total + sumAmounts(imbalance.billedAmounts) - sumAmounts(imbalance.orderedAmounts),
          0
        );
      return total + amount;
    }, 0);

  const diffsGroupedByProductIndex = groupDiffsByProductIndex(order);
  const totalDifference = sumTotalDifferences(diffsGroupedByProductIndex);

  const matchedLinks = order.billings
    .filter(({ netAmount }) => netAmount < 0)
    .flatMap((billing) =>
      billing.orderLinks
        .map((link, index) => ({
          id: billing.id,
          document: billing.source?.document,
          orderId: link.order?.id,
          amount: link.amount / 100,
          linkIndex: index,
        }))
        .filter(({ orderId }) => orderId === order.id)
    );

  const balance = Math.min(totalDifference + sumAmounts(matchedLinks), Number((orderRelatedBalance / 100).toFixed(2)));

  return {
    id: order.id,
    date: order.date,
    document: order.source?.document,
    orderProducts: diffsGroupedByProductIndex.map(({ orderProduct }) => orderProduct),
    incompleteTotal: diffsGroupedByProductIndex.some(({ customerValues }) => isNil(customerValues.price)),
    totalDifference,
    totalDifferenceAgorot: Math.round(totalDifference * 100),
    totalPriceDifference: sumTotalPriceDifferences(diffsGroupedByProductIndex),
    totalQuantityDifference: sumTotalQuantityDifferences(diffsGroupedByProductIndex),
    diffTypes: getDiffsTypes(order),
    isFullyMatched: !!order.billings.length && balance === 0,
    balance,
    balanceAgorot: Math.round(balance * 100),
    matchedLinks: matchedLinks.map((link) => {
      return {
        ...link,
        amount: Math.round(link.amount * 100),
      };
    }),
  };
};

const sumAmounts = pipe(map(prop('amount')), sum);

export const createCreditRow = (billing) => {
  const amountMatched = billing.orderLinks.reduce((total, link) => total + (link.amount || 0), 0);
  return {
    id: billing.id,
    date: billing.date,
    document: billing.source?.document,
    totalAmount: billing.netAmount,
    isFullyMatched: amountMatched === billing.netAmount,
    balance: billing.netAmount - amountMatched,
    matchedLinks: billing.orderLinks.map((link, index) => {
      return {
        id: billing.id,
        document: billing.source?.document,
        orderId: link.order?.id,
        amount: link.amount,
        linkIndex: index,
      };
    }),
  };
};

const DIFF_FIELDS = ['quantity', 'price', 'discount'];
const cleanDiffs = pipe(pick(DIFF_FIELDS), reject(isNil));

export const groupDiffsByProductIndex = (order) => {
  return flatten(
    order.differences.map((diff) => {
      const diffs = [];
      const pricingDiffExists = diff.price || diff.discount;
      if (pricingDiffExists) diffs.push(createOrderItem(order, diff, pricingDiffExists));
      if (diff.quantity) diffs.push(createOrderItem(order, diff));
      return diffs;
    })
  );
};

export const sumTotalDifferences = (diffsGroupedByProductIndex) => {
  return Number(
    diffsGroupedByProductIndex
      .reduce((acc, { orderProduct }) => {
        return acc + orderProduct.amount;
      }, 0)
      .toFixed(2)
  );
};

export const sumTotalPriceDifferences = (diffsGroupedByProductIndex) => {
  return sumTotalDifferences(diffsGroupedByProductIndex.filter((diff) => diff.orderProduct.type === 'pricing'));
};

export const sumTotalQuantityDifferences = (diffsGroupedByProductIndex) => {
  return sumTotalDifferences(diffsGroupedByProductIndex.filter((diff) => diff.orderProduct.type === 'quantity'));
};

export const navigateMonth = (currentDate, jumps) => {
  const date = new Date(currentDate);
  date.setMonth(date.getMonth() + jumps);
  return getISOMonth(date);
};

export const getISOMonth = (date) => `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, 0)}`;

const createOrderItem = (order, diff, isPricingDiff) => {
  const orderItem = order.products.find(({ id }) => id === diff.customerItemId);
  return {
    orderProduct: {
      ...pick(['name', 'sku'], diff.product ?? { name: i18n.t('creditMatchModal.matching.general'), sku: '-' }),
      amount: isPricingDiff ? diff.pricingAmount : diff.quantityAmount,
      type: isPricingDiff ? 'pricing' : 'quantity',
      solved: isPricingDiff ? diff.netPrice.solved : diff.quantity.solved,
    },
    customerValues: pick(DIFF_FIELDS, orderItem || { quantity: 0, price: 0 }),
    supplierValues: {
      ...pick(DIFF_FIELDS, orderItem || { quantity: 0, price: 0 }),
      ...map(prop('supplierValue'), cleanDiffs(diff)),
    },
  };
};
