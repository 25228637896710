<template>
  <div class="matching-table-wrapper">
    <el-table
      height="100%"
      style="width: 100%"
      class="matching-table"
      :data="ordersDifferences"
      default-expand-all=""
      @row-click="$emit('row-click', $event)"
    >
      <el-table-column v-slot="{ $index }" width="30">
        <div class="radio-btn-wrapper">
          <div class="radio-btn" :class="{ selected: $index === selectedIndex }" />
        </div>
      </el-table-column>
      <el-table-column
        v-slot="{ row: { date } }"
        :label="$t('creditMatchModal.table.common.label.date')"
        min-width="90"
      >
        {{ formatDate(date) }}
      </el-table-column>
      <el-table-column
        v-slot="{ row: { document } }"
        :label="$t('creditMatchModal.table.differences.label.order')"
        min-width="130"
      >
        <a href="" @click.prevent.stop="$emit('document-click', document.id)">
          {{ $t(`document.exports.schema.type.shortName.${document.type}`) }} {{ document.documentNumber }}
        </a>
      </el-table-column>
      <el-table-column v-slot="{ row: { diffTypes } }" :label="$t('creditMatchModal.table.common.label.diffType')">
        <template v-for="formattedDiff in formatDiffType(diffTypes)">
          <el-tooltip :key="formattedDiff.key" effect="dark" :content="formattedDiff.tooltipText" placement="top">
            <span>
              {{ formattedDiff.text }}
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        v-slot="{ row: { totalDifference, incompleteTotal } }"
        :label="$t('creditMatchModal.table.common.label.totalDiff')"
        min-width="90"
      >
        <template v-if="totalDifference">
          <AsteriskTooltip :value="totalDifference" :show-asterisk="incompleteTotal" />
        </template>
        <template v-else>
          {{ $t('commons.unknown') }}
        </template>
      </el-table-column>
      <el-table-column
        v-slot="{ row: { totalQuantityDifference } }"
        :label="$t('creditMatchModal.table.common.label.quantityDifference')"
        min-width="90"
      >
        {{ totalQuantityDifference ? formatMoneyShekels(totalQuantityDifference) : '-' }}
      </el-table-column>
      <el-table-column
        v-slot="{ row: { totalPriceDifference } }"
        :label="$t('creditMatchModal.table.common.label.priceDifference')"
        min-width="90"
      >
        {{ totalPriceDifference ? formatMoneyShekels(totalPriceDifference) : '-' }}
      </el-table-column>
      <el-table-column
        v-slot="{ row: { isFullyMatched, balance, balanceAgorot, matchedLinks, totalDifferenceAgorot } }"
        :label="$t('creditMatchModal.table.common.label.matchBalance')"
        min-width="90"
      >
        <template v-if="matchedLinks.length === 0">{{ $t('creditMatchModal.table.common.notMatched') }}</template>
        <template v-else>
          <el-popover placement="left" width="396">
            <MatchedList
              :total="totalDifferenceAgorot"
              :balance="balanceAgorot"
              :matched-links="matchedLinks"
              @unlink-matched="$emit('unlink-matched', $event)"
            />
            <a slot="reference" href="" :class="{ 'fully-matched': isFullyMatched }" @click.prevent.stop>
              {{ isFullyMatched ? $t('creditMatchModal.table.common.matched') : formatMoneyShekels(balance) }}
            </a>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column type="expand" width="20">
        <template slot-scope="props">
          <ProductDifferenceTable :order-products="props.row.orderProducts" />
        </template>
      </el-table-column>
    </el-table>
    <div class="table-summary">
      <div>
        {{ $t('creditMatchModal.table.differences.label.total') }}
      </div>
      <div class="total-summary">
        <AsteriskTooltip :value="summaries.totalDiff" :show-asterisk="summaries.isIncompleteTotal" />
        <AsteriskTooltip :value="summaries.totalBalance" :show-asterisk="summaries.isIncompleteTotal" />
      </div>
    </div>
  </div>
</template>

<script>
import { options } from '@/locale/dateConfig';

import ProductDifferenceTable from './ProductDifferenceTable';
import AsteriskTooltip from './AsteriskTooltip';
import MatchedList from './MatchedList';
import { useCurrency } from '@/modules/core/compositions/money-currency';

const generateUniqKey = () => Date.now() * Math.random();

export default {
  components: { ProductDifferenceTable, MatchedList, AsteriskTooltip },
  props: {
    ordersDifferences: { type: Array, default: () => [] },
    selectedIndex: { type: Number, default: -1 },
  },
  setup() {
    const { formatToCurrency } = useCurrency();
    return { formatToCurrency };
  },
  computed: {
    summaries() {
      let isIncompleteTotal = false;

      const { totalDiff, totalBalance } = this.ordersDifferences.reduce(
        (acc, { totalDifference, incompleteTotal, balance }) => {
          if (incompleteTotal) {
            isIncompleteTotal = true;
          }
          acc.totalDiff += totalDifference;
          acc.totalBalance += balance;

          return acc;
        },
        { totalDiff: 0, totalBalance: 0 }
      );

      return {
        totalDiff,
        totalBalance,
        isIncompleteTotal,
      };
    },
  },
  methods: {
    formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, options.short) : null;
    },
    formatMoneyShekels(value) {
      return this.formatToCurrency(value) ?? '-';
    },
    formatDiffType(diffTypes) {
      return diffTypes.map((type) => ({
        key: generateUniqKey(),
        tooltipText: this.$t(`creditMatchModal.table.common.diffType.${type}.text`),
        text: this.$t(`creditMatchModal.table.common.diffType.${type}.label`),
      }));
    },
  },
};
</script>

<style scoped lang="scss">
@import './style';
</style>
