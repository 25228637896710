<template>
  <div class="match-wrapper">
    <div class="amount difference">
      <div class="title-amount">{{ $t('creditMatchModal.matching.difference') }}</div>
      <h2 class="fw-bold">{{ formatMoneyShekels(totalDifference) || $t('creditMatchModal.matching.notSelected') }}</h2>
    </div>
    <div class="matching">
      <h3 class="fw-bold pb-1">{{ $t('creditMatchModal.matching.amount') }}</h3>
      <el-form ref="form" :model="inputForm" inline>
        <el-form-item prop="matchingValue" :rules="inputRule">
          <div class="matching-container">
            <div class="match-amount">
              <InputMoneyShekels v-model="inputForm.matchingValue" />
              <Button :disabled="!inputForm.matchingValue" @click="submit">
                {{ $t('creditMatchModal.matching.match') }}
              </Button>
            </div>
            <Button type="text" class="total-diff-amount" @click="inputForm.matchingValue = totalDifference">
              {{ $t('creditMatchModal.matching.totalDiffAmount') }}
            </Button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="amount credit">
      <div class="title-amount">{{ $t('creditMatchModal.matching.credit') }}</div>
      <h2 class="fw-bold">{{ formatMoney(totalCredit, true) || $t('creditMatchModal.matching.notSelected') }}</h2>
    </div>
  </div>
</template>

<script>
import { Button, InputMoneyShekels } from '@/modules/core/components';
import { useCurrency } from '@/modules/core/compositions/money-currency';

export default {
  components: { Button, InputMoneyShekels },
  props: {
    totalDifference: { type: Number, default: null },
    totalCredit: { type: Number, default: null },
  },
  setup() {
    const { formatToCurrency } = useCurrency();

    return { formatToCurrency };
  },
  data() {
    return {
      inputForm: {
        matchingValue: null,
      },
    };
  },
  computed: {
    inputRule() {
      return {
        type: 'number',
        validator: this.validator,
        trigger: 'change',
      };
    },
  },
  watch: {
    totalDifference() {
      this.inputForm.matchingValue = null;
    },
  },
  methods: {
    formatMoney(value, abs) {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const amount = abs ? Math.abs(Number(value / 100)) : Number(value / 100);
        return this.formatToCurrency(amount);
      }
      return null;
    },
    formatMoneyShekels(value, abs) {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const amount = abs ? Math.abs(Number(value)) : Number(value);
        return this.formatToCurrency(amount);
      }
      return null;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        this.$emit('match', this.inputForm.matchingValue);
      });
    },
    validator(rule, value, callback) {
      const max = Math.min(this.totalDifference || 0, Math.abs(this.totalCredit / 100) || 0);
      if (value < 0) {
        callback(new Error(this.$i18n.t('creditMatchModal.matching.positiveRule')));
      }
      if (value > max) {
        callback(new Error(this.$i18n.t('creditMatchModal.matching.amountRule')));
      }
      callback();
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

$border: 1px solid $outline;

.match-wrapper {
  display: flex;
  width: 60%;
  max-width: 1000px;
  margin: auto;
  border: $border;
  border-radius: 6px;
  color: $typography-primary;

  .matching {
    flex: 2;
    padding: 10px 10px;
    border-right: $border;
    border-left: $border;
    display: flex;
    flex-direction: column;
    align-items: center;

    .matching-container {
      justify-self: center;

      .match-amount {
        display: flex;
        gap: 10px;
      }

      .total-diff-amount {
        font-weight: initial;
        font-size: 1em;
        padding: 0px;
      }
    }
  }
  .amount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    background: $white;

    .title-amount {
      text-align: center;
      word-break: normal;
      font-size: 0.86em;
      font-weight: 400;
    }
    &.difference {
      border-radius: 0px 6px 6px 0px;
    }

    &.credit {
      border-radius: 6px 0px 0px 6px;
    }
  }
}

:deep(.el-input__prefix) {
  left: auto;
  right: 5px;
}
</style>
