import { computed, ref } from 'vue';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { DateTime } from 'luxon';

import { useLoading } from '@/modules/core';
import { PATCH_BILLING_ORDER_LINKS } from '@/modules/billing';

import { ORDER_DIFFERENCES_QUERY } from './graphql';

export const useOrders = (supplierId, businessId, defaultMonth) => {
  const date = ref(DateTime.fromFormat(defaultMonth, 'yyyy-MM'));
  const from = computed(() => date.value.startOf('month'));
  const to = computed(() => date.value.endOf('month'));

  const {
    result,
    loading: queryLoading,
    refetch,
  } = useQuery(ORDER_DIFFERENCES_QUERY, () => ({
    businessId: businessId.value,
    supplierId: supplierId.value,
    from: from.value.toISODate(),
    to: to.value.toISODate(),
  }));
  const orders = computed(() => result.value?.orders?.nodes ?? []);
  const variables = ref({ supplierId, defaultMonth });
  const loading = useLoading(queryLoading, variables);

  return {
    orders,
    range: computed(() => ({
      start: from.value.toJSDate(),
      end: to.value.toJSDate(),
    })),
    loading,
    refetch,
    goForward: () => {
      date.value = date.value.plus({ months: 1 });
    },
    goBackward: () => {
      date.value = date.value.minus({ months: 1 });
    },
  };
};

export const useBillingPatch = (supplierId, ordersRange) => {
  const {
    mutate: patchBilling,
    loading,
    onDone,
  } = useMutation(PATCH_BILLING_ORDER_LINKS, {
    update: (cache, { data: { billingPatch: billing } }) => {
      const { start: from, end: to } = ordersRange.value;
      const ordersCache = cache.readQuery({
        query: ORDER_DIFFERENCES_QUERY,
        variables: {
          businessId: billing.businessId,
          supplierId: supplierId.value,
          from: DateTime.fromJSDate(from).toISODate(),
          to: DateTime.fromJSDate(to).toISODate(),
        },
      });

      const updatedOrders = ordersCache?.orders.nodes.map((order) => {
        const updatedBillings = [...order.billings];
        const orderInBillingLinks = billing.orderLinks.some(({ order }) => order && order.id === order.id);
        const billingInOrderIndex = updatedBillings.findIndex(({ id }) => id === billing.id);
        if (orderInBillingLinks && billingInOrderIndex === -1) {
          updatedBillings.push(billing);
        }
        if (!orderInBillingLinks && billingInOrderIndex !== -1) {
          updatedBillings.splice(billingInOrderIndex, 1);
        }
        return {
          ...order,
          billings: updatedBillings,
        };
      });

      cache.writeQuery({
        query: ORDER_DIFFERENCES_QUERY,
        variables: {
          businessId: billing.businessId,
          supplierId: supplierId.value,
          from: DateTime.fromJSDate(from).toISODate(),
          to: DateTime.fromJSDate(to).toISODate(),
        },
        data: { orders: { nodes: updatedOrders } },
      });
    },
  });

  return {
    patchBilling,
    loading,
    onDone,
  };
};
