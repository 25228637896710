<template>
  <el-table class="inner-table" :data="transformedOrderProducts">
    <el-table-column v-slot="{ row: { sku } }" :label="$t('creditMatchModal.table.order.label.sku')" min-width="110">
      {{ sku }}
    </el-table-column>
    <el-table-column
      v-slot="{ row: { name } }"
      :label="$t('creditMatchModal.table.order.label.product')"
      min-width="130"
    >
      {{ name }}
    </el-table-column>
    <el-table-column
      v-slot="{ row: { type } }"
      :label="$t('creditMatchModal.table.common.label.diffType')"
      min-width="80"
    >
      <el-tooltip :key="type.key" effect="dark" :content="type.tooltipText" placement="top">
        <span>
          {{ type.text }}
        </span>
      </el-tooltip>
    </el-table-column>
    <el-table-column
      v-slot="{ row: { amount, solved } }"
      :label="$t('creditMatchModal.table.common.label.totalDiff')"
      min-width="375"
    >
      {{ formatToCurrency(amount) || $t('commons.unknown') }}
      <template v-if="solved">
        <ArrowLeftIcon height="16px" width="16px" />
        <ApprovedGreenIcon height="16px" width="16px" />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { ApprovedGreenIcon, ArrowLeftIcon } from '@/assets/icons/';
import { useCurrency } from '@/modules/core/compositions/money-currency';

const generateUniqKey = () => Date.now() * Math.random();

export default {
  components: { ApprovedGreenIcon, ArrowLeftIcon },
  props: { orderProducts: { type: Array, required: true } },
  setup() {
    const { formatToCurrency } = useCurrency();

    return { formatToCurrency };
  },
  computed: {
    transformedOrderProducts() {
      return this.orderProducts.map((orderProduct) => ({
        ...orderProduct,
        type: this.formatDiffType(orderProduct.type),
      }));
    },
  },
  methods: {
    formatDiffType(type) {
      return {
        key: generateUniqKey(),
        tooltipText: this.$t(`creditMatchModal.table.common.diffType.${type}.text`),
        text: this.$t(`creditMatchModal.table.common.diffType.${type}.label`),
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.inner-table {
  ::v-deep th {
    background: $secondary;
  }
  ::v-deep td {
    background: $secondary;
  }
}
</style>
